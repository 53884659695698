import React, { useEffect , useRef } from 'react';
import img1 from "./hero-bg.jpg"
import emailjs from '@emailjs/browser';
import Aboutus from './Aboutus'
import { Helmet } from 'react-helmet';
import Galleryhome from './Galleryhome'
import { Link } from 'react-router-dom';
const Home = () => {
  useEffect(() => {
    document.title = "Willow Decorist | Home";
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ogvyjz9', 'template_hyt2ln1', form.current, {
        publicKey: 'juFSC2ydvVOkPl4bB',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
  <>
  <Helmet>
        <meta name="description" content="At Willow Decorist, we create unforgettable events, including weddings, corporate events, and more. Let us bring your vision to life!" />
        <meta name="keywords" content="Event Planning, Wedding Decor, Corporate Events, Birthday Parties, Event Management, Social Gatherings, Sporting Events, Award Ceremonies, Music Performances, Exhibition Planning, Custom Themes, Venue Decoration, Seamless Experience, Creative Designs, Professional Organizers, Tailored Events, Unforgettable Celebrations, Strategic Event Planning, Comprehensive Services, Expert Event Coordination, Transforming Visions into Celebrations, Your Event, Our Expertise, Creating Memorable Experiences, Personalized Event Solutions, Elevate Your Special Occasion, Crafting Unique Event Environments, From Concept to Reality, Let’s Make Your Event Extraordinary, Attention to Detail in Every Celebration, Bringing Your Ideas to Life" />3      </Helmet>
  <main class="main">

<section id="hero" class="hero section dark-background">

  <img src={img1} alt="" data-aos="fade-in"/>

  <div class="container">
    <div class="row">
      <div class="col-lg-8 d-flex flex-column align-items-center align-items-lg-start">
        <h2 data-aos="fade-up" data-aos-delay="100">Welcome to <span>Willow Decorist</span></h2>
                <div class="d-flex mt-4" data-aos="fade-up" data-aos-delay="300">
          <Link to="/WillowDecorist_Events" class="cta-btn" onClick={scrollToTop}>Our Events</Link>
          <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist" class="cta-btn">Book a Event</Link>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-center justify-content-center mt-5 mt-lg-0">
        
      </div>
    </div>
  </div>

</section>
<Aboutus/>
<Galleryhome/>
<section id="contact" class="contact section">
<div class="container section-title" data-aos="fade-up">
  <h2>Contact</h2>
  <p>Contact Us</p>
</div>

<div class="mb-5" data-aos="fade-up" data-aos-delay="200">
<iframe className='mapp' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d850.7068583248007!2d74.42632834342446!3d31.473932889109992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919096c15b88cff%3A0x42fd85104b6aa992!2sMoaz%20Steel%20Head%20Office!5e0!3m2!1sen!2s!4v1727095969480!5m2!1sen!2s" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>

<div class="container" data-aos="fade-up" data-aos-delay="100">

  <div class="row gy-4">

    <div class="col-lg-4">
      <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
        <i class="bi bi-geo-alt flex-shrink-0"></i>
        <div>
          <h3>Location</h3>
          <p>Office No 2, Mezzanine Floor 
          Moaz Arcade Baidian Road Lahore Cantt.</p>
        </div>
      </div>

      <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
        <i class="bi bi-telephone flex-shrink-0"></i>
        <div>
          <h3>Open Hours</h3>
          <p>Monday-Saturday:<br/>11:00 AM - 2:00 AM</p>
        </div>
      </div>

      <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
        <i class="bi bi-telephone flex-shrink-0"></i>
        <div>
          <h3>Call Us</h3>
          <p>+923020431702</p>
        </div>
      </div>

      <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
        <i class="bi bi-envelope flex-shrink-0"></i>
        <div>
          <h3>Email Us</h3>
          <p>info@willowdecorist.com
          </p>
        </div>
      </div>

    </div>

    <div class="col-lg-8">
    <form ref={form} onSubmit={sendEmail} class="php-email-form" data-aos="fade-up" data-aos-delay="200">
      <div class="row gy-4">

<div class="col-md-6">
  <input type="text" name="First" class="form-control" placeholder="Your Name" required=""/>
</div>

<div class="col-md-6 ">
  <input type="email" class="form-control" name="Last" placeholder="Your Email" required=""/>
</div>

<div class="col-md-12">
  <select name="Subject" class="form-control bg-dark text-white" required="">
    <option value="" disabled selected>Select Subject</option>
    <option value="Inquiry">WEDDINGS</option>
    <option value="Feedback">BUSINESS EVENTS</option>
    <option value="Support">SPORTING EVENTS</option>
    <option value="Collaboration">SOCIAL EVENTS</option>
    <option value="Inquiry">BIRTHDAY PARTIES</option>
    <option value="Feedback">AWARD CEREMONIES</option>
    <option value="Support">EXHIBITION AND SEMINARS</option>
  </select>
</div>

<div class="col-md-12">
  <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
</div>

<div class="col-md-12 text-center">
  <div class="loading">Loading</div>
  <div class="error-message"></div>
  <div class="sent-message">Your message has been sent. Thank you!</div>

  <input className='btn ' type="submit" value="Send" />
</div>

</div>
      </form>
    </div>

  </div>

</div>

</section>
</main>
  </>
  )
}

export default Home