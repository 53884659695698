import React from 'react'
import {Link} from "react-router-dom"
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    
  <>
   <footer id="footer" class="footer">

<div class="container footer-top">
  <div class="row gy-4">
    <div class="col-lg-4 col-md-6 footer-about">
      <Link onClick={scrollToTop}  href="index.html" class="logo d-flex align-items-center">
        <span class="sitename">Willow Decorist</span>
      </Link>
      <div class="footer-contact pt-3">
        <p>Office No 2, Mezzanine Floor</p>
        <p> 
        Moaz Arcade Baidian Road Lahore Cantt.</p>
        <p class="mt-3"><strong>Phone:</strong> <span>+92-306-8459341</span></p>
        <p><strong>Email:</strong> <span>info@willowdecorist.com</span></p>
        <p><strong>Open hours:</strong> <span> 11:00 AM – 2:00 AM</span></p>
      </div>
      <div class="social-links d-flex mt-4">
        <Link onClick={scrollToTop}  href=""><i class="bi bi-twitter-x"></i></Link>
        <Link onClick={scrollToTop}  href=""><i class="bi bi-facebook"></i></Link>
        <Link onClick={scrollToTop}  href=""><i class="bi bi-instagram"></i></Link>
        <Link onClick={scrollToTop}  href=""><i class="bi bi-linkedin"></i></Link>
      </div>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Useful Links</h4>
      <ul>
        <li><Link onClick={scrollToTop}  to="https://willowdecorist.com">Home</Link></li>
        <li><Link onClick={scrollToTop}  to="/WillowDecorist_About">About</Link></li>
        <li><Link onClick={scrollToTop}  to="/WillowDecorist_Events">Event</Link></li>
        <li><Link onClick={scrollToTop}  to="/WillowDecorist_Contact">Contact Us</Link></li>
        <li><Link onClick={scrollToTop}  to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist" target='blank'>Book Events</Link></li>
      </ul>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Our Services</h4>
      <ul>
        <li><Link onClick={scrollToTop}  to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist" target='blank'>Weddings</Link></li>
        <li><Link onClick={scrollToTop}  to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist" target='blank'>Business Events</Link></li>
        <li><Link onClick={scrollToTop}  to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist" target='blank'>Sporting Events</Link></li>
        <li><Link onClick={scrollToTop}  to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist" target='blank'>Social Events</Link></li>
        <li><Link onClick={scrollToTop}  to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist" target='blank'>Birthday parties</Link></li>
      </ul>
    </div>

    <div class="col-lg-4 col-md-12 footer-newsletter">
      <h4>Willow Decorist</h4>
      <p>“Where Every Event Becomes a Masterpiece”</p>
     
    </div>

  </div>
</div>

<div class="container copyright text-center mt-4">
  <p>© <span>Copyright</span> <strong class="px-1 sitename">Willow Decorist</strong> <span>All Rights Reserved</span></p>
  <div class="credits">

    Designed by <Link onClick={scrollToTop}  href="https://divcodex.com">DivCodeX</Link>
  </div>
</div>

</footer>
  
  </>
  )
}

export default Footer