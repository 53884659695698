import React, { useEffect } from 'react';
import img2 from "./Aboutus.jpg"
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
const Aboutus = () => {
  
  useEffect(() => {
    document.title = "Willow Decorist | About US";
  }, []);
  return (
 
   <>
    <Helmet>
        <meta name="description" content="At Willow Decorist, we create unforgettable events, including weddings, corporate events, and more. Let us bring your vision to life!" />
        <meta name="keywords" content="Event Planning, Wedding Decor, Corporate Events, Birthday Parties, Event Management, Social Gatherings, Sporting Events, Award Ceremonies, Music Performances, Exhibition Planning, Custom Themes, Venue Decoration, Seamless Experience, Creative Designs, Professional Organizers, Tailored Events, Unforgettable Celebrations, Strategic Event Planning, Comprehensive Services, Expert Event Coordination, Transforming Visions into Celebrations, Your Event, Our Expertise, Creating Memorable Experiences, Personalized Event Solutions, Elevate Your Special Occasion, Crafting Unique Event Environments, From Concept to Reality, Let’s Make Your Event Extraordinary, Attention to Detail in Every Celebration, Bringing Your Ideas to Life" />      </Helmet>
   <section id="about" className="about section">
 
<div className="container" data-aos="fade-up" data-aos-delay="100">

  <div className="row gy-4 mt-5">
      <div className="container section-title" data-aos="fade-up">
        <h2>WHY US</h2>
        <p>Why Choose Willow Decorist</p>
      </div>
    <div className="col-lg-6 order-1 order-lg-2">
      <img src={img2} className="img-fluid about-img" alt=""/>
    </div>
    <div className="col order-2 order-lg-1 content">
      <h3>"Turning Visions into Unforgettable Celebrations"</h3>
      <p className="fst-italic">
      At Willow Decorist, we believe that your home should be a reflection of your unique style and personality. Our mission is to transform your living spaces into beautifully designed, harmonious environments where you feel truly at home.
      </p>
      <ul>
        <li><i className="bi bi-check2-all"></i> <span><b>Imaginative Vision:</b>  We accept each occasion recounting a story and are energetic about rejuvenating that story with our inventive plans and careful preparation.</span></li>
        <li><i className="bi bi-check2-all"></i> <span><b>Far-reaching Services:</b>  From idea to the end, we offer a full set-up of occasion-the-board and stylistic layout administrations, guaranteeing consistent and tranquil experiences for our clients.</span></li>
        <li><i className="bi bi-check2-all"></i> <span><b>Customized Approach:</b>  From the concept to completion, we offer a full suite of event management and decor services, ensuring seamless and stress-free experiences for our clients.

</span></li>
        <li><i className="bi bi-check2-all"></i> <span><b>Experienced Team:</b> With long periods of involvement with the business, our group has the information and mastery to oversee occasions of all sizes and intricacies.</span></li>
        
      </ul>
      <h1>Let’s Create Something Extraordinary</h1>
      <p>
      Ready to turn your event into a memorable experience? Contact Willow Decorist today to discuss your vision, and let us bring your ideas to life with our exceptional event management and decor services.
      </p>
    </div>
  </div>

</div>

</section>

<section id="why-us" className="why-us section">

 
      <div className="container section-title" data-aos="fade-up">
        <p>Every Event Becomes a Masterpiece</p>
      </div>

      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="card-item">
              <span>Weddings</span>
              
             <Link  to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Your wedding day is one of the most important days of your life, and we are dedicated to making it truly unforgettable. From heartfelt services to captivating gatherings, we tailor every detail to reflect your interesting sentimental fiction."</p></Link>
            </div>
          </div>
          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="card-item">
              <span>Business Events</span>
              <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Elevate your corporate image with impeccably managed business events. Whether it's a product launch, a seminar, or an annual conference, we ensure every aspect aligns with your company’s vision and goals."</p></Link>

            </div>
          </div>

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
            <div className="card-item">
              <span>SPORTING EVENTS</span>
              <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Empower your crowd and create a completely exhilarating environment with our master management of sporting and game events. From little nearby rivalries to enormous scope competitions, we handle all the strategies so you can focus on the game."</p></Link>

            </div>
          </div>

        </div>
        <div className="row gy-4">

<div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
  <div className="card-item">
    <span>SOCIAL EVENTS:</span>
    <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"From elegant dinners to lively parties, Willow Decorist brings style and sophistication to any social gathering. Our attention to detail ensures that your event is memorable for all the right reasons."</p></Link>

  </div>
</div>
<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
  <div className="card-item">
    <span>BIRTHDAY PARTIES</span>
    <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Your wedding day is one of the most important days of your life, and we are dedicated to making it truly unforgettable. From heartfelt services to captivating gatherings, we tailor every detail to reflect your interesting sentimental fiction."</p></Link>

  </div>
</div>

<div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
  <div className="card-item">
    <span>AWARD CEREMONIES:</span>
    <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Make your next birthday celebration extraordinary with our custom-themed parties. Whether it’s a child’s magical adventure or an adult’s milestone celebration, we’ll create a party that’s as special as the person being celebrated"</p></Link>

  </div>
</div>

</div>
<div className="row gy-4">

<div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
  <div className="card-item">
    <span>MUSIC PERFORMANCES:</span>
    <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Celebrate accomplishments in style with a flawlessly executed grant function. Our group handles everything from stage plan to lighting, ensuring each champ feels like a star."</p></Link>

  </div>
</div>


<div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
  <div className="card-item">
    <span>EXHIBITION AND SEMINARS</span>
    <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>" Dazzle your participants with our fastidiously arranged presentations and courses. We deal with everything about, stall plan to speaker courses of action, establishing a climate helpful for systems administration and learning."</p></Link>

  </div>
</div>

</div>

      </div>

    </section>
</>

  )
}

export default Aboutus