import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
const Event = () => {
  useEffect(() => {
    document.title = "Willow Decorist | Events";
  }, []);
  return (
   <>
   <Helmet>
 
        <meta name="description" content="At Willow Decorist, we create unforgettable events, including weddings, corporate events, and more. Let us bring your vision to life!" />
        <meta name="keywords" content="Event Planning, Wedding Decor, Corporate Events, Birthday Parties, Event Management, Social Gatherings, Sporting Events, Award Ceremonies, Music Performances, Exhibition Planning, Custom Themes, Venue Decoration, Seamless Experience, Creative Designs, Professional Organizers, Tailored Events, Unforgettable Celebrations, Strategic Event Planning, Comprehensive Services, Expert Event Coordination, Transforming Visions into Celebrations, Your Event, Our Expertise, Creating Memorable Experiences, Personalized Event Solutions, Elevate Your Special Occasion, Crafting Unique Event Environments, From Concept to Reality, Let’s Make Your Event Extraordinary, Attention to Detail in Every Celebration, Bringing Your Ideas to Life" />      </Helmet>
     <div class="page-title position-relative headerimg1" data-aos="fade">
      <div class="container position-relative">
        <h1>Events<br/></h1>
        <nav class="breadcrumbs">
        </nav>
      </div>
    </div>
    <section id="why-us" className="why-us section">

 
<div className="container section-title" data-aos="fade-up">
  <p>Every Event Becomes a Masterpiece</p>
</div>

<div className="container">

  <div className="row gy-4">

    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
      <div className="card-item">
        <span>Weddings</span>
        
       <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Your wedding day is one of the most important days of your life, and we are dedicated to making it truly unforgettable. From heartfelt services to captivating gatherings, we tailor every detail to reflect your interesting sentimental fiction."</p></Link>
      </div>
    </div>
    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
      <div className="card-item">
        <span>Business Events</span>
        <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Elevate your corporate image with impeccably managed business events. Whether it's a product launch, a seminar, or an annual conference, we ensure every aspect aligns with your company’s vision and goals."</p></Link>

      </div>
    </div>

    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
      <div className="card-item">
        <span>SPORTING EVENTS</span>
        <Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Empower your crowd and create a completely exhilarating environment with our master management of sporting and game events. From little nearby rivalries to enormous scope competitions, we handle all the strategies so you can focus on the game."</p></Link>

      </div>
    </div>

  </div>
  <div className="row gy-4">

<div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
<div className="card-item">
<span>SOCIAL EVENTS:</span>
<Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"From elegant dinners to lively parties, Willow Decorist brings style and sophistication to any social gathering. Our attention to detail ensures that your event is memorable for all the right reasons."</p></Link>

</div>
</div>
<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
<div className="card-item">
<span>BIRTHDAY PARTIES</span>
<Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Your wedding day is one of the most important days of your life, and we are dedicated to making it truly unforgettable. From heartfelt services to captivating gatherings, we tailor every detail to reflect your interesting sentimental fiction."</p></Link>

</div>
</div>

<div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
<div className="card-item">
<span>AWARD CEREMONIES:</span>
<Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Make your next birthday celebration extraordinary with our custom-themed parties. Whether it’s a child’s magical adventure or an adult’s milestone celebration, we’ll create a party that’s as special as the person being celebrated"</p></Link>

</div>
</div>

</div>
<div className="row gy-4">

<div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
<div className="card-item">
<span>MUSIC PERFORMANCES:</span>
<Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>"Celebrate accomplishments in style with a flawlessly executed grant function. Our group handles everything from stage plan to lighting, ensuring each champ feels like a star."</p></Link>

</div>
</div>


<div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
<div className="card-item">
<span>EXHIBITION AND SEMINARS</span>
<Link to="https://api.whatsapp.com/send?phone=+923020431702&text=Hello%20Willow%20Decorist"><p>" Dazzle your participants with our fastidiously arranged presentations and courses. We deal with everything about, stall plan to speaker courses of action, establishing a climate helpful for systems administration and learning."</p></Link>

</div>
</div>

</div>

</div>

</section>
   </>
  )
}

export default Event